<template>
  <el-dialog
    :title="isEdit ? '编辑程序' : '新建程序'"
    :visible.sync="dialogVisible"
    width="800px"
    center
    :close-on-click-modal="false"
    @closed="clearForm"
  >
    <div class="item_box">
      <div class="label">程序名称</div>
      <div class="content">
        <el-input v-model="name" placeholder="程序名称" maxlength="8" />
      </div>
    </div>

    <div class="item_box">
      <div class="label">启用时段</div>
      <div class="content">
        <Calendar placeholder="启用时段" :value.sync="dateStr" />
      </div>
    </div>

    <div class="item_box">
      <div class="label">运行时间</div>
      <div class="content3">
        <div
          class="date_picker"
          v-for="(item, index) in time_data"
          :key="index"
        >
          <el-time-picker
            is-range
            v-model="item.runTime"
            range-separator="-"
            start-placeholder="开始时间"
            end-placeholder="结束时间"
            placeholder="选择时间范围"
            value-format="HH:mm"
            format="HH:mm"
            class="w200"
            :clearable="false"
          >
          </el-time-picker>
          <img
            class="close"
            src="../../../assets/program_close_icon.png"
            alt=""
            @click="delPicker(index)"
          />
        </div>

        <img
          v-if="time_data.length < 3"
          @click="addPicker"
          class="program_add"
          src="../../../assets/program_add.png"
        />
      </div>
    </div>

    <div class="item_box">
      <div class="label">模式</div>
      <div class="content">
        <el-select v-model="mode" placeholder="请选择模式" class="w200">
          <el-option
            v-for="item in modeList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </div>
    </div>

    <div class="item_box">
      <div class="label">设定温度</div>
      <div class="content">
        <el-select v-model="temp" placeholder="请选择温度" class="w200">
          <el-option
            v-for="item in 7"
            :key="item + 19"
            :label="item + 19 + '℃'"
            :value="item + 19"
          >
          </el-option>
        </el-select>
      </div>
    </div>

    <div class="item_box">
      <div class="label">扫风</div>
      <div class="content2">
        <div class="switch_box">
          <span>上下扫风</span>
          <el-switch v-model="windUpDown" active-color="#50e399"></el-switch>
        </div>

        <div class="switch_box">
          <span>左右扫风</span>
          <el-switch v-model="windLefeRight" active-color="#50e399"></el-switch>
        </div>
      </div>
    </div>

    <div class="item_box change_program_radio">
      <div class="label">调节风速</div>
      <div class="content">
        <el-radio-group v-model="wind" fill="#50e399">
          <el-radio :label="20">20</el-radio>
          <el-radio :label="40">40</el-radio>
          <el-radio :label="60">60</el-radio>
          <el-radio :label="80">80</el-radio>
          <el-radio :label="100">100</el-radio>
          <el-radio :label="120">自动风</el-radio>
        </el-radio-group>
      </div>
    </div>

    <div class="item_box change_program_radio">
      <div class="label">程序模式</div>
      <div class="content">
        <el-radio-group v-model="start_mode" fill="#50e399">
          <el-radio :label="0">仅解锁</el-radio>
          <el-radio :label="1">解锁并运行</el-radio>
        </el-radio-group>
      </div>
    </div>

    <span slot="footer" class="dialog-footer">
      <el-button @click="dialogVisible = false">取 消</el-button>
      <el-button type="primary" @click="confirm">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import Calendar from '@/components/calendar/calendar.vue'
import { AddProgramApi, UpdateProgramApi } from '@/api'

export default {
  components: { Calendar },
  data() {
    return {
      // 规则id
      id_task: '',
      modeList: [
        { id: 1, name: '自动模式' },
        { id: 2, name: '制冷模式' },
        { id: 3, name: '除湿模式' },
        { id: 4, name: '制热模式' },
        { id: 5, name: '送风模式' }
      ],
      // 模式
      mode: '',
      // 选中温度
      temp: '',
      // 是否添加
      isEdit: false,
      // 编辑的id
      editId: '',
      // 是否显示弹窗
      dialogVisible: false,
      // 程序名称
      name: '',
      // 启用时段
      dateStr: '',
      // 运行时间
      time_data: [
        {
          runTime: ['00:00', '23:59']
        }
      ],
      // 上下扫风
      windUpDown: false,
      // 左右扫风
      windLefeRight: false,
      // 风速
      wind: 120,
      // 开始模式 0:解锁 1:解锁并启动
      start_mode: 0
    }
  },
  methods: {
    // 清除所有的数据
    clearForm() {
      this.name = ''
      this.mode = ''
      this.temp = ''
      this.dateStr = ''
      this.time_data = [
        {
          runTime: ['00:00', '23:59']
        }
      ]
      this.windUpDown = false
      this.windLefeRight = false
      this.wind = 120
      this.start_mode = 0
    },

    // 添加
    add(id_task) {
      this.isEdit = false
      this.id_task = id_task
      this.dialogVisible = true
    },

    // 编辑程序
    edit(row) {
      this.isEdit = true
      this.editId = row.id
      this.name = row.name
      // 自定义时间选择器的回显
      const startStr = (row.start_date + '').padStart(4, '0')
      const endStr = (row.end_date + '').padStart(4, '0')
      this.dateStr = `${startStr.substr(0, 2)}-${startStr.substr(
        2,
        4
      )}—${endStr.substr(0, 2)}-${endStr.substr(2, 4)}`
      // 时间参数的筛选
      const dateArr = []
      row.time.map(i => {
        dateArr.push({
          runTime: [
            i.start_time.padStart(8, '0').substr(0, 5),
            i.end_time.padStart(8, '0').substr(0, 5)
          ]
        })
      })
      this.time_data = dateArr
      // 模式
      this.mode = row.air_mode
      // 温度
      this.temp = row.air_temp
      // 扫风
      if (row.air_wind_up_down) {
        this.windUpDown = true
      } else {
        this.windUpDown = false
      }
      if (row.air_wind_left_right) {
        this.windLefeRight = true
      } else {
        this.windLefeRight = false
      }
      // 风速
      this.wind = row.air_wind
      this.start_mode = row.start_mode
      this.dialogVisible = true
    },

    // 添加picker
    addPicker() {
      this.time_data.push({
        runTime: ['00:00', '23:59']
      })
    },

    // 删除picker
    delPicker(index) {
      this.time_data.splice(index, 1)
    },

    // 点击确定
    confirm() {
      const name = this.name.trim()
      if (!name || name.length > 8) {
        return this.$message.warning('程序名称长度为1-8位')
      }
      if (!this.dateStr) {
        return this.$message.warning('请选择启用时段')
      }
      if (!this.time_data.length) {
        return this.$message.warning('请至少选择一个运行时间')
      }
      if (!this.mode) {
        return this.$message.warning('请选择模式')
      }
      if (!this.mode) {
        return this.$message.warning('请选择模式')
      }
      if (!this.temp) {
        return this.$message.warning('请选择设定温度')
      }
      if (this.isEdit) {
        this.saveEdit()
      } else {
        this.addProgram()
      }
    },

    // 添加
    async addProgram() {
      const params = {
        id_task: this.id_task,
        name: this.name.trim(),
        air_mode: this.mode,
        air_wind: this.wind,
        air_wind_left_right: this.windLefeRight ? 1 : 0,
        air_wind_up_down: this.windUpDown ? 1 : 0,
        air_temp: this.temp,
        run_type: 1,
        start_mode: this.start_mode
      }
      const dateStrArr = this.dateStr.split('—')
      params.start_date = dateStrArr[0].replace('-', '') / 1
      params.end_date = dateStrArr[1].replace('-', '') / 1
      const timeList = []
      this.time_data.map(i => {
        timeList.push({
          start_time: i.runTime[0] + ':00',
          end_time: i.runTime[1] + ':59'
        })
      })
      params.time = timeList
      const { ret, data, msg } = await AddProgramApi(params)
      if (ret !== 0) {
        return this.$message.error(msg)
      }
      this.$message.success('添加成功')
      this.dialogVisible = false
      this.$emit('refresh', 1)
    },

    // 编辑
    async saveEdit() {
      const params = {
        id_program: this.editId,
        name: this.name.trim(),
        air_mode: this.mode,
        air_wind: this.wind,
        air_wind_left_right: this.windLefeRight ? 1 : 0,
        air_wind_up_down: this.windUpDown ? 1 : 0,
        air_temp: this.temp,
        run_type: 1,
        start_mode: this.start_mode
      }
      const dateStrArr = this.dateStr.split('—')
      params.start_date = dateStrArr[0].replace('-', '') / 1
      params.end_date = dateStrArr[1].replace('-', '') / 1
      const timeList = []
      this.time_data.map(i => {
        timeList.push({
          start_time: i.runTime[0] + ':00',
          end_time: i.runTime[1] + ':59'
        })
      })
      params.time = timeList
      const { ret, data, msg } = await UpdateProgramApi(params)
      if (ret !== 0) {
        return this.$message.error(msg)
      }
      this.$message.success('更新成功')
      this.dialogVisible = false
      this.$emit('refresh', 1)
    }
  }
}
</script>

<style lang="scss" scoped>
.item_box {
  display: flex;
  margin: 20px 0;

  .label {
    flex: 0 0 120px;
    height: 40px;
    line-height: 40px;
    text-align: right;
    padding-right: 20px;
    font-size: 14px;
    font-family: Adobe Heiti Std;
    font-weight: normal;
    color: #12203e;
  }

  .content {
    display: flex;
    align-items: center;
  }

  .content2 {
    display: flex;
    align-items: center;

    .switch_box {
      margin-right: 44px;

      span {
        font-size: 14px;
        font-family: Adobe Heiti Std;
        font-weight: normal;
        color: #12203e;
        margin-right: 26px;
      }
    }
  }

  .content3 {
    display: flex;
    flex-wrap: wrap;

    .date_picker {
      flex: 0 0 238px;
      margin-right: 20px;
      display: flex;
      align-items: center;

      &:nth-child(3) {
        margin-top: 20px;
      }

      .close {
        width: 26px;
        height: 26px;
        margin-left: 12px;
        cursor: pointer;
      }
    }

    .program_add {
      width: 40px;
      height: 40px;
      cursor: pointer;
    }
  }
}
</style>
<style lang="scss">
.change_program_radio {
  .el-radio__input.is-checked .el-radio__inner {
    background-color: #50e399;
    border-color: #50e399;
  }

  .el-radio__input.is-checked + .el-radio__label {
    color: #50e399;
  }
}
</style>
