<template>
  <el-dialog
    :visible.sync="dialogVisible"
    :title="type ? '设备温度区间设定' : '项目温度区间设定'"
    width="800px"
    @closed="clearForm"
  >
    <el-form :model="ruleForm" :rules="rules" ref="ruleForm">
      <div class="line">
        <span class="label">自动模式温度设定范围</span>
        <el-form-item prop="auto_temp_min">
          <el-input
            v-model="ruleForm.auto_temp_min"
            size="medium"
            class="temp_ipt"
            placeholder="最小温度"
          ></el-input>
        </el-form-item>
        <div class="dot"></div>
        <el-form-item prop="auto_temp_max">
          <el-input
            v-model="ruleForm.auto_temp_max"
            size="medium"
            class="temp_ipt"
            placeholder="最大温度"
          ></el-input>
        </el-form-item>
      </div>

      <div class="line">
        <span class="label">制冷模式温度设定范围</span>
        <el-form-item prop="cool_temp_min">
          <el-input
            v-model="ruleForm.cool_temp_min"
            size="medium"
            class="temp_ipt"
            placeholder="最小温度"
          ></el-input>
        </el-form-item>
        <div class="dot"></div>
        <el-form-item prop="cool_temp_max">
          <el-input
            v-model="ruleForm.cool_temp_max"
            size="medium"
            class="temp_ipt"
            placeholder="最大温度"
          ></el-input>
        </el-form-item>
      </div>

      <div class="line">
        <span class="label">制热模式温度设定范围</span>
        <el-form-item prop="heat_temp_min">
          <el-input
            v-model="ruleForm.heat_temp_min"
            size="medium"
            class="temp_ipt"
            placeholder="最小温度"
          ></el-input>
        </el-form-item>
        <div class="dot"></div>
        <el-form-item prop="heat_temp_max">
          <el-input
            v-model="ruleForm.heat_temp_max"
            size="medium"
            class="temp_ipt"
            placeholder="最大温度"
          ></el-input>
        </el-form-item>
      </div>
    </el-form>

    <div class="temp_footer">
      <el-button @click="dialogVisible = false" size="medium">取 消</el-button>
      <el-button
        type="primary"
        @click="confirm"
        size="medium"
        style="margin-left:24px;"
        >确 定</el-button
      >
    </div>
  </el-dialog>
</template>

<script>
const checkTempMin = (_, value, cb) => {
  const reg = /^[1-3][0-9]$/
  if (!reg.test(value)) {
    return cb(new Error('输入错误'))
  }
  if (Number(value) < 17) {
    return cb(new Error('最小温度应该 >=17'))
  }
  cb()
}

const checkTempMax = (_, value, cb) => {
  const reg = /^[1-3][0-9]$/
  if (!reg.test(value)) {
    return cb(new Error('输入错误'))
  }
  if (Number(value) > 30) {
    return cb(new Error('最大温度应该 <=30'))
  }
  cb()
}

export default {
  data() {
    return {
      // 是否显示弹窗
      dialogVisible: false,
      // 0项目 1设备
      type: 1,
      // 温度表单
      ruleForm: {
        auto_temp_min: '',
        auto_temp_max: '',
        cool_temp_min: '',
        cool_temp_max: '',
        heat_temp_min: '',
        heat_temp_max: ''
      },
      // 温度规则
      rules: {
        auto_temp_min: [
          { required: true, message: '请输入温度', trigger: 'blur' },
          { validator: checkTempMin, trigger: 'blur' }
        ],
        auto_temp_max: [
          { required: true, message: '请输入温度', trigger: 'blur' },
          { validator: checkTempMax, trigger: 'blur' }
        ],
        cool_temp_min: [
          { required: true, message: '请输入温度', trigger: 'blur' },
          { validator: checkTempMin, trigger: 'blur' }
        ],
        cool_temp_max: [
          { required: true, message: '请输入温度', trigger: 'blur' },
          { validator: checkTempMax, trigger: 'blur' }
        ],
        heat_temp_min: [
          { required: true, message: '请输入温度', trigger: 'blur' },
          { validator: checkTempMin, trigger: 'blur' }
        ],
        heat_temp_max: [
          { required: true, message: '请输入温度', trigger: 'blur' },
          { validator: checkTempMax, trigger: 'blur' }
        ]
      }
    }
  },
  methods: {
    // 添加账号
    open(type, row) {
      if (type === 1) {
        const {
          auto_temp_min,
          auto_temp_max,
          cool_temp_min,
          cool_temp_max,
          heat_temp_min,
          heat_temp_max
        } = row
        this.ruleForm.auto_temp_min = auto_temp_min + ''
        this.ruleForm.auto_temp_max = auto_temp_max + ''
        this.ruleForm.cool_temp_min = cool_temp_min + ''
        this.ruleForm.cool_temp_max = cool_temp_max + ''
        this.ruleForm.heat_temp_min = heat_temp_min + ''
        this.ruleForm.heat_temp_max = heat_temp_max + ''
      }
      this.type = type
      this.dialogVisible = true
    },

    // 点击确定
    confirm() {
      this.$refs.ruleForm.validate(async _ => {
        if (!_) return
        const auto_temp_min = Number(this.ruleForm.auto_temp_min)
        const auto_temp_max = Number(this.ruleForm.auto_temp_max)
        const cool_temp_min = Number(this.ruleForm.cool_temp_min)
        const cool_temp_max = Number(this.ruleForm.cool_temp_max)
        const heat_temp_min = Number(this.ruleForm.heat_temp_min)
        const heat_temp_max = Number(this.ruleForm.heat_temp_max)
        if (
          auto_temp_min > auto_temp_max ||
          cool_temp_min > cool_temp_max ||
          heat_temp_min > heat_temp_max
        ) {
          this.$message.warning('温度区间错误')
          return
        }
        this.dialogVisible = false
        this.$emit('confirm', {
          auto_temp_min,
          auto_temp_max,
          cool_temp_min,
          cool_temp_max,
          heat_temp_min,
          heat_temp_max
        })
      })
    },

    // 清除表单信息
    clearForm() {
      this.$refs.ruleForm.resetFields()
    }
  }
}
</script>

<style lang="scss" scoped>
.temp_footer {
  text-align: center;
}

.line {
  display: flex;
  align-items: center;
  justify-content: center;

  .label {
    font-size: 14px;
    font-family: Alibaba PuHuiTi;
    font-weight: 600;
    color: #12203e;
    padding-bottom: 26px;
    margin-right: 13px;
  }

  .temp_ipt {
    width: 113px;
  }

  .dot {
    width: 5px;
    height: 2px;
    font-size: 14px;
    font-family: Alibaba PuHuiTi;
    font-weight: 500;
    color: #12203e;
    opacity: 0.5;
    margin: 0 14px 0 15px;
    padding-bottom: 26px;
  }
}
</style>
