<template>
  <el-dialog
    title="使用此规则的设备"
    :visible.sync="dialogVisible"
    width="800px"
  >
    <div class="dialog_container">
      <div class="device_box">
        <div class="device" v-for="item in deviceList" :key="item.id">
          <el-tooltip
            class="item"
            effect="light"
            :content="item.addr"
            placement="top"
          >
            <span>{{
              item.addr.length > 8 ? item.addr.substr(0, 7) + '...' : item.addr
            }}</span>
          </el-tooltip>
        </div>
      </div>

      <div class="count">
        总设备数: <span>{{ deviceList.length }}</span>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import { GetRuleCtrlDeviceApi } from '@/api'

export default {
  data() {
    return {
      dialogVisible: false,
      // 设备列表
      deviceList: []
    }
  },
  methods: {
    // 显示弹窗
    async open(row) {
      const params = {
        id_task: row.id
      }
      const { ret, data, msg } = await GetRuleCtrlDeviceApi(params)
      if (ret !== 0) {
        return this.$message.error(msg)
      }
      if (!data.length) {
        return this.$message.warning('该规则下暂无设备!')
      }
      this.deviceList = data
      this.dialogVisible = true
    }
  }
}
</script>

<style lang="scss" scoped>
.dialog_container {
  padding: 0 10px;

  .device_box {
    border: 1px solid #ebedf2;
    border-radius: 8px;
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 48px;

    .device {
      flex: 0 0 20%;
      height: 38px;
      line-height: 38px;
      border-right: 1px solid #ebedf2;
      border-bottom: 1px solid #ebedf2;
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #12203e;
      padding: 0 8px;
      text-align: center;

      &:nth-child(8n) {
        border-right: none;
      }
    }
  }

  .count {
    height: 40px;
    line-height: 40px;
    padding-bottom: 10px;
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #252631;
    margin-top: 12px;

    span {
      color: #0062ff;
    }
  }
}
</style>
