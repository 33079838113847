<template>
  <div class="intelligent_control">
    <div class="card">
      <!-- 标题 -->
      <div class="title_box">
        <div></div>
        <span>智能控制规则</span>
      </div>
      <!-- 选择器 -->
      <div class="selector">
        <div class="selects">
          <el-select
            v-model="id_operator"
            placeholder="全部运营商"
            style="margin-right: 14px"
            class="w200"
            @change="id_project = ''"
            v-supAdmin
          >
            <el-option
              v-for="item in operatorList"
              :key="item.id_operator"
              :label="item.name_operator"
              :value="item.id_operator"
            >
            </el-option>
          </el-select>

          <el-select
            v-model="id_project"
            placeholder="全部项目"
            style="margin-right: 14px"
            class="w200"
          >
            <el-option
              v-for="item in computedProject"
              :key="item.id"
              :label="item.name_project"
              :value="item.id"
            >
            </el-option>
          </el-select>

          <el-input
            class="w200"
            placeholder="输入控制规则名称查询"
            v-model="name"
            prefix-icon="el-icon-search"
            style="margin: 0 14px 0 0"
          ></el-input>
        </div>
        <div class="btns">
          <el-button type="success" icon="el-icon-search" @click="search"
            >查询</el-button
          >
          <el-button type="info" icon="el-icon-refresh-left" @click="reset"
            >重置</el-button
          >
          <el-button type="primary" icon="el-icon-plus" @click="addRule"
            >新建规则</el-button
          >
        </div>
      </div>

      <!-- 表格 -->
      <div class="table_box">
        <el-table
          :data="ruleList"
          style="width: 100%"
          border
          :header-cell-style="{
            background: '#fafafb'
          }"
          size="small"
          row-key="id"
          :expand-row-keys="expandRowKeys"
          @expand-change="expandChange"
        >
          <el-table-column type="expand" label="">
            <template>
              <div class="inner_box">
                <div class="custom_table">
                  <div class="item" v-for="item in expandData" :key="item.id">
                    <div class="title">
                      <span class="name">{{ item.name }}</span>
                      <div class="is_current" v-if="item.in">
                        (当前启用程序)
                      </div>
                    </div>
                    <div class="table_title">
                      <div class="col col2">启用时段</div>
                      <div class="col col3">运行时间</div>
                      <div class="col col2">运行模式</div>
                      <div class="col col2">设定温度</div>
                    </div>
                    <div class="table_content">
                      <div class="col col2">{{ getRunDate(item) }}</div>
                      <div class="col col3">
                        <span v-for="it in item.time" :key="it.id">{{
                          getRunTime(it)
                        }}</span>
                      </div>
                      <div class="col col2">{{ item.air_mode | modeFmt }}</div>
                      <div class="col col2">{{ item.air_temp }}℃</div>
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="规则名称" prop="name"></el-table-column>
          <el-table-column
            v-if="level === 100"
            label="运营商"
            prop="name_operator"
          ></el-table-column>
          <el-table-column label="项目" prop="name_project"></el-table-column>
          <el-table-column label="运行中设备" prop="cnt_device_run">
            <template v-slot="scope">
              <span class="primary">{{ scope.row.cnt_device_run }}</span>
            </template>
          </el-table-column>
          <el-table-column label="设备总数">
            <template v-slot="scope">
              <span class="edit cursor" @click="showUseRuleDevice(scope.row)">
                {{ scope.row.cnt_device }}
              </span>
            </template>
          </el-table-column>
          <el-table-column label="操作">
            <template v-slot="scope">
              <div class="table_control">
                <img
                  @click="editRule(scope.row)"
                  src="../../assets/table_edit.png"
                  alt=""
                  title="编辑"
                />
                <img
                  @click="toRuleDetail(scope.row)"
                  src="../../assets/table_rule2.png"
                  alt=""
                  title="规则"
                />
                <img
                  @click="showDelAlert(scope.row)"
                  src="../../assets/table_delete.png"
                  alt=""
                  title="删除"
                />
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>

      <!-- 分页功能 -->
      <div class="pages">
        <el-pagination
          @size-change="sizeChange"
          @current-change="currentPageChange"
          :current-page="page_id + 1"
          :page-sizes="[20, 50, 100, 200]"
          :page-size="page_num"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>

    <!-- 添加 修改规则 -->
    <ChangeRule ref="changeRuleRef" @refresh="refresh" />
    <!-- 弹窗 -->
    <Alert ref="alertRef" @confirm="delRule" />

    <!-- 使用此规则的设备 -->
    <UseRuleDevice ref="useRuleDeviceRef" />
  </div>
</template>

<script>
import operator from '@/mixins/operator.js'
import project from '@/mixins/project.js'
import { GetRuleListApi, DelRuleApi, GetProgramGroupApi } from '@/api'
import ChangeRule from './page1_cpts/change_rule.vue'
import Alert from '@/components/alert/alert.vue'
import UseRuleDevice from './page1_cpts/use_task_device.vue'

export default {
  mixins: [operator, project],
  components: { ChangeRule, Alert, UseRuleDevice },
  data() {
    return {
      // 权限级别
      level: '',
      // 运营商
      id_operator: '',
      // 项目
      id_project: '',
      // 规则名称
      name: '',
      // 规则列表
      ruleList: [],
      // 分页
      total: 0,
      page_id: 0,
      page_num: 20,
      // 展开行的数组
      expandRowKeys: [],
      // 展开行数据
      expandData: []
    }
  },
  methods: {
    // 分页尺寸变化
    sizeChange(size) {
      this.page_num = size
      this.getRuleList()
    },

    // 当前页变化
    currentPageChange(page) {
      this.page_id = page - 1
      this.getRuleList()
    },

    // 查询
    search() {
      this.page_id = 0
      this.getRuleList()
    },

    // 重置
    reset() {
      this.id_operator = ''
      this.id_project = ''
      this.name = ''
      this.page_id = 0
      this.getRuleList()
    },

    // 新建规则
    addRule() {
      this.$refs.changeRuleRef.add(this.projectList, this.operatorList)
    },

    // 获取规则列表
    async getRuleList() {
      const params = {
        page_id: this.page_id,
        page_num: this.page_num
      }
      if (this.id_operator) {
        params.id_operator = this.id_operator
      }
      if (this.id_project) {
        params.id_project = this.id_project
      }
      if (this.name.trim()) {
        params.name = this.name.trim()
      }
      const { ret, data, msg } = await GetRuleListApi(params)
      if (ret !== 0) {
        return this.$message.error(msg)
      }
      this.total = data.cnt_all
      this.ruleList = data.data
    },

    // 子组件刷新
    refresh(data) {
      if (data) {
        this.$parent.changePage(2, data)
        this.getRuleList()
      } else {
        this.getRuleList()
      }
    },

    // 编辑规则
    editRule(row) {
      this.$refs.changeRuleRef.edit(row)
    },

    // 去规则详情
    toRuleDetail(row) {
      this.expandRowKeys = []
      this.$parent.changePage(2, row)
    },

    // 显示规则弹窗
    showDelAlert(row) {
      this.$refs.alertRef.alert(
        {
          title: '删除提醒',
          msg: '您确定要删除该规则吗? 此操作不可恢复!'
        },
        row,
        1
      )
    },

    // 删除规则
    async delRule({ info, type }) {
      const params = {
        id_task: info.id
      }
      const { ret, data, msg } = await DelRuleApi(params)
      if (ret !== 0) {
        return this.$message.error(msg)
      }
      this.$message.success('删除成功')
      this.getRuleList()
    },

    // 展开行放生变化
    expandChange(expandRows, expanded) {
      // 判断是否关闭当前
      if (this.expandRowKeys[0] === expandRows.id) {
        this.expandRowKeys = []
        return
      }
      this.expandRowKeys = []
      this.getProgramList(expandRows.id)
    },

    // 获取程序列表
    async getProgramList(id) {
      const params = {
        id_task: id
      }
      const { ret, data, msg } = await GetProgramGroupApi(params)
      if (ret !== 0) {
        return this.$message.error(msg)
      }
      if (!data.program.length) {
        return this.$message.warning('暂无程序')
      }
      this.expandData = data.program
      this.expandRowKeys = [id]
    },

    // 计算启用时段
    getRunDate(row) {
      const start = (row.start_date + '').padStart(4, '0')
      const end = (row.end_date + '').padStart(4, '0')
      return `${start.substr(0, 2)}-${start.substr(2, 4)}—${end.substr(
        0,
        2
      )}-${end.substr(2, 4)}`
    },

    // 计算启用时间
    getRunTime(row) {
      const start = row.start_time.padStart(8, '0')
      const end = row.end_time.padStart(8, '0')
      return `${start.substr(0, 5)} - ${end.substr(0, 5)}`
    },

    // 显示是用此规则的设备
    showUseRuleDevice(row) {
      this.$refs.useRuleDeviceRef.open(row)
    }
  },
  created() {
    this.level = this.$store.getters.level
    if (this.level === 100) {
      this.getOperatorList()
    }
    this.getProjectList()
    this.getRuleList()
  },
  computed: {
    // 计算项目
    computedProject() {
      if (this.level === 100) {
        if (this.id_operator) {
          return this.projectList.filter(i => {
            return i.id_operator === this.id_operator
          })
        } else {
          return this.projectList
        }
      } else {
        return this.projectList
      }
    }
  },
  filters: {
    modeFmt(v) {
      switch (v) {
        case 1:
          return '自动'
        case 2:
          return '制冷'
        case 3:
          return '除湿'
        case 4:
          return '制热'
        case 5:
          return '送风'
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.intelligent_control {
  padding: 0 22px;
}

.card {
  margin-top: 24px;
  min-height: calc(100vh - 160px);

  .title_box {
    height: 48px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #ebedf2;

    div {
      width: 4px;
      height: 18px;
      background: linear-gradient(-75deg, #50e379, #50e398);
      box-shadow: 0px 3px 4px 0px rgba(80, 227, 146, 0.35);
      margin: 0 11px 0 26px;
    }

    span {
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #12203e;
    }
  }

  .selector {
    display: flex;
    justify-content: space-between;
    padding: 0 22px;

    .selects {
      margin: 20px 0;
    }

    .btns {
      flex: 0 0 340px;
      text-align: right;
      margin: 20px 0;
    }
  }

  .table_box {
    padding: 0 22px;

    .inner_box {
      padding: 5px 20px;

      .custom_table {
        width: 100%;
        border: 1px solid #ebedf2;
        border-radius: 8px;

        .item {
          .title {
            display: flex;
            align-items: center;
            height: 40px;
            background-color: #fafafb;
            border-top: 1px solid #ebedf2;
            padding-left: 15px;

            .name {
              font-size: 14px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              color: #12203e;
            }

            .is_current {
              font-size: 14px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              color: #0062ff;
              margin-left: 14px;
            }
          }

          .table_title {
            height: 36px;
            border: 1px solid #ebedf2;
            display: flex;
            align-items: center;

            .col {
              text-align: center;
              font-size: 14px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              color: #12203e;
            }

            .col2 {
              flex: 2;
            }

            .col3 {
              flex: 3;
            }
          }

          .table_content {
            height: 36px;
            display: flex;
            align-items: center;

            .col {
              font-size: 14px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              color: #12203e;
              text-align: center;
            }

            .col2 {
              flex: 2;
            }

            .col3 {
              flex: 3;

              span {
                margin: 0 5px;
              }
            }
          }
        }
      }
    }
  }
}
</style>
