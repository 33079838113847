import { GetFloorApi } from '@/api'
export default {
  data() {
    return {
      floorList: []
    }
  },

  methods: {
    async getFloorList(p) {
      const params = { ...p }
      const { ret, data, msg } = await GetFloorApi(params)
      if (ret !== 0) {
        return this.$message.error(msg)
      }
      this.floorList = data
      return data
    }
  }
}

// [
//   {floor: 1},
//   {floor: 2}
// ]
