<template>
  <el-dialog
    title="导出excel"
    :visible.sync="dialogVisible"
    width="678px"
    :close-on-click-modal="false"
    @closed="closed"
  >
    <div class="progress_box">
      <el-progress
        :text-inside="true"
        :stroke-width="26"
        :percentage="percentage"
      ></el-progress>
    </div>
    <div class="msg_box">
      <div class="msg1">数据下载中, 请勿关闭当前弹窗!</div>
    </div>
  </el-dialog>
</template>

<script>
// 数据量太大, 不走objectdefineproperty
import * as XLSX from 'xlsx'
import DateFmt from '@/utils/DateFmt.js'
let tempArr = []

export default {
  data() {
    return {
      // 是否显示导出弹窗
      dialogVisible: false,
      // 进度
      percentage: 0,
      // 一次性加载多少条数据
      total: 0,
      page_num: 250,
      page_id: 0,
      // 参数
      params: {},
      // 函数
      func: null,
      // 结果路径
      datapath: [],
      // 总数路径
      countpath: [],
      // 导出的键
      keys: [],
      // 首行文本
      titles: [],
      // 格式化参数
      fmt: {},
      // 文件名
      filename: '默认文件名',
      // 是否停止(*意外)关闭弹窗
      isStop: false
    }
  },
  methods: {
    // dialog关闭事件
    closed() {
      this.isStop = true
      this.page_id = 0
      this.percentage = 0
      this.total = 0
      this.filename = '默认文件名'
    },

    // 导出
    export(option) {
      this.isStop = false
      const {
        filename,
        func,
        params = {},
        datapath,
        countpath,
        kw = {},
        fmt = {}
      } = option
      this.dialogVisible = true
      // 处理参数/分页
      delete params.page_id
      delete params.page_num
      this.params = params
      // 函数
      this.func = func
      // 结果路径
      this.datapath = datapath
      // 处理下载的键值对
      const keys = []
      const titles = []
      Object.keys(kw).map(i => {
        keys.push(i)
        titles.push(kw[i])
      })
      this.keys = keys
      this.titles = titles
      // 格式化参数
      this.fmt = fmt
      // 文件名
      this.filename = filename
      // 总数
      this.countpath = countpath
      // 获取数据
      this.getData()
    },

    // 获取数据钩子
    async getData() {
      console.log('获取数据')
      const params = {
        ...this.params,
        page_id: this.page_id,
        page_num: this.page_num
      }
      const { ret, data, msg } = await this.func(params)
      if (ret !== 0) {
        return this.$message.error(msg)
      }
      // 拿到数组
      const result = this.datapath.reduce((cur, pre, index, arr) => {
        return cur[pre]
      }, data)
      // 拿到总数
      this.total = this.countpath.reduce((cur, pre) => {
        return cur[pre]
      }, data)
      // 计算百分比 percentage
      const downloadCount = this.page_id * this.page_num + result.length
      let percentage = parseInt((downloadCount * 100) / this.total)
      percentage = percentage > 100 ? 100 : percentage
      this.percentage = percentage
      this.handleData(result)
    },

    // 处理数据
    handleData(list) {
      const arr = []
      list.map(row => {
        const temp = []
        this.keys.map(k => {
          if (this.fmt[k]) {
            temp.push(this.fmt[k](row[k], row))
          } else {
            temp.push(row[k])
          }
        })
        arr.push(temp)
      })
      // 和前面下载的数据合并
      tempArr = tempArr.concat(arr)
      // 判断是否下载数据
      if (tempArr.length >= 40000) {
        this.downloadExcel()
      }
      // 人为停止下载
      if (this.isStop) {
        this.downloadExcel()
        return
      }
      // 还有数据
      if (this.page_id * this.page_num + list.length < this.total) {
        this.page_id++
        this.getData()
      } else {
        // 没有数据了
        this.downloadExcel()
      }
    },

    // 下载 excel
    downloadExcel() {
      // 下载的数据
      console.log(tempArr)
      const sheet = [this.titles].concat(tempArr)
      const worksheet = XLSX.utils.aoa_to_sheet(sheet, {
        skipHeader: true
      })
      const book = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(book, worksheet, 'SHEET')
      const filename = `${this.filename}-${DateFmt.format()}.xlsx`
      XLSX.writeFile(book, filename)
      tempArr = []
      console.log('执行下载')
    }
  }
}
</script>

<style lang="scss" scoped>
.progress_box {
  padding: 0 20px;
}

.msg_box {
  padding: 40px 20px 20px;
}
</style>
