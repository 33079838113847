export const roleFmt = v => {
  switch (v) {
    case 100:
      return '超级管理员'
    case 1:
      return '运营商管理员'
    case 2:
      return '项目管理员'
    case 3:
      return '楼栋管理员'
  }
}

// 设备状态格式化 1自动模式 2制冷模式 3除湿模式 4制热模式 5送风模式
export const deviceModeFmt = v => {
  switch (v) {
    case 1:
      return '自动模式'
    case 2:
      return '制冷模式'
    case 3:
      return '除湿模式'
    case 4:
      return '制热模式'
    case 5:
      return '送风模式'
  }
}

export const modeFmt = v => {
  switch (v) {
    case 1:
      return '自动'
    case 2:
      return '制冷'
    case 3:
      return '除湿'
    case 4:
      return '制热'
    case 5:
      return '送风'
  }
}

// 日志类型格式化
export const actionTypeFmt = v => {
  switch (v) {
    case 1:
      return '设备启动'
    case 2:
      return '设备关闭'
    case 3:
      return '空调锁定'
    case 4:
      return '空调解锁'
    case 5:
      return '删除控制规则'
  }
}
