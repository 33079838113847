<template>
  <el-dialog :visible.sync="dialogVisible" title="空调管理" width="800px">
    <div class="form_box">
      <el-form :model="ruleForm" :rules="rules" ref="formRef" label-width="100px">
        <el-row>
          <el-col :span="12">
            <el-form-item label="名称" prop="addr">
              <el-input v-model="ruleForm.addr" maxlength="12"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="楼层" prop="floor">
              <el-select v-model="ruleForm.floor" placeholder="请选择楼层" class="w100">
                <el-option v-for="item in floorList" :key="item.id" :label="item.name" :value="item.id"></el-option>
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="楼栋" prop="id_building">
              <el-select v-model="ruleForm.id_building" placeholder="请选择楼栋" class="w100">
                <el-option v-for="item in buildingList" :key="item.id" :label="item.name_building"
                  :value="item.id"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>

    <span slot="footer" class="dialog-footer">
      <el-button @click="dialogVisible = false">取 消</el-button>
      <el-button type="primary" @click="save">保 存</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { EditDeviceApi } from '@/api'
import {
  check_device_name,
  check_floor,
  check_build
} from '@/utils/validator.js'
const floorList = Array.from({ length: 32 }, (_, i) => {
  return {
    id: i + 1,
    name: i + 1 + ' F'
  }
})

export default {
  props: ['buildingList'],
  data() {
    return {
      // 是否显示弹窗
      dialogVisible: false,
      // 设备信息
      deviceInfo: '',
      // 楼层列表
      floorList,
      // 表单
      ruleForm: {
        addr: '',
        floor: '',
        id_building: ''
      },
      // 规则
      rules: {
        addr: check_device_name,
        floor: check_floor,
        id_building: check_build
      }
    }
  },
  methods: {
    // 添加账号
    config(row) {
      this.deviceInfo = row
      this.dialogVisible = true
      this.$nextTick(() => {
        this.ruleForm.addr = row.addr
        this.ruleForm.floor = row.floor
        this.ruleForm.id_building = row.id_building
      })
    },

    // 确定保存
    save() {
      this.$refs.formRef.validate(async _ => {
        if (!_) return
        const params = {
          id: this.deviceInfo.id,
          addr: this.ruleForm.addr,
          floor: this.ruleForm.floor,
          id_building: this.ruleForm.id_building
        }
        const { ret, data, msg } = await EditDeviceApi(params)
        if (ret !== 0) {
          return this.$message.error(msg)
        }
        this.$message.success('修改成功')
        this.dialogVisible = false
        this.$emit('refresh')
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.form_box {
  padding: 64px 60px 32px;
}
</style>
