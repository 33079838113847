<template>
  <div class="intelligent_control">
    <div class="card">
      <!-- 标题 -->
      <div class="title_box">
        <div class="left">
          <div class="color_block"></div>
          <span class="title1">智能控制规则</span>
          <div class="split">/</div>
          <div class="title2">设置程序</div>
        </div>
        <div>
          <el-button
            type="primary"
            class="el-icon-back"
            size="medium"
            @click="goBack"
            >返 回</el-button
          >
        </div>
      </div>

      <!-- 内容 -->
      <div class="container_box">
        <!-- 左边的规则列表 -->
        <div class="rule_box">
          <!-- 运营商 项目 规则名 -->
          <div class="info_box">
            <el-input
              v-if="getLevel === 100"
              suffix-icon="el-icon-arrow-down"
              :value="ruleInfo.name_operator"
              disabled
              size="medium"
              style="margin-right: 1%; width:180px"
            >
            </el-input>
            <el-input
              suffix-icon="el-icon-arrow-down"
              :value="ruleInfo.name_project"
              disabled
              size="medium"
              class="w200"
              style="margin-right: 1%; width:180px"
            >
            </el-input>
            <el-input
              :value="ruleInfo.name"
              disabled
              size="medium"
              class="w200"
              style="width:180px"
            >
            </el-input>
          </div>

          <div class="program_box">
            <div class="title">
              <span>程序</span>
              <div>
                <el-button size="mini" type="success" @click="showAddProgram"
                  >添加</el-button
                >
              </div>
            </div>

            <div class="rule_item">
              <!-- 内部的规则展示  -->
              <div
                class="item"
                :class="item.id === expandId ? '' : 'closed'"
                v-for="item in program"
                :key="item.id"
              >
                <div class="head">
                  <div class="name">{{ item.name }}</div>
                  <div class="right_box">
                    <el-button
                      type="primary"
                      size="small"
                      @click="editProgram(item)"
                      >编辑</el-button
                    >
                    <el-button
                      type="danger"
                      plain
                      size="small"
                      style="margin: 0 27px 0 20px"
                      @click="showDelAlert(item)"
                      >删除</el-button
                    >
                    <i
                      class="el-icon-arrow-down cursor"
                      @click="expandChange(item.id)"
                    ></i>
                  </div>
                </div>
                <div class="rule_container">
                  <div class="table_box">
                    <div class="tr">
                      <div class="td">启用时段</div>
                      <div class="td2">{{ getDateStr(item) }}</div>
                    </div>
                    <div class="tr">
                      <div class="td">运行时间</div>
                      <div class="td2">
                        <span v-for="it in item.time" :key="it.id"
                          >{{ it.start_time.padStart(8, '0').substr(0, 5) }}—{{
                            it.end_time.padStart(8, '0').substr(0, 5)
                          }}</span
                        >
                      </div>
                    </div>
                    <div class="tr">
                      <div class="td">模式</div>
                      <div class="td2">{{ item.air_mode | deviceModeFmt }}</div>
                    </div>
                    <div class="tr">
                      <div class="td">设定温度</div>
                      <div class="td2">{{ item.air_temp }}</div>
                    </div>
                    <div class="tr">
                      <div class="td">扫风</div>
                      <div class="td2">{{ getWindMode(item) }}</div>
                    </div>
                    <div class="tr">
                      <div class="td">调节风速</div>
                      <div class="td2">
                        {{ item.air_wind > 100 ? '自动风' : item.air_wind }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- 右边的设备列表 -->
        <div class="device_box">
          <div class="title_box">
            <div class="title">规则使用设备</div>
            <div>
              <el-button type="success" size="mini" @click="showAddDevice"
                >添加</el-button
              >
            </div>
          </div>
          <div class="device_wrapper">
            <el-scrollbar style="height: 100%">
              <div class="device_list">
                <div class="device" v-for="item in deviceList" :key="item.id">
                  <div class="close_box">
                    <i
                      class="el-icon-close"
                      style="font-size: 12px; font-weight:600"
                      @click="showDelDeviceAlert(item)"
                    ></i>
                  </div>
                  <el-tooltip
                    :content="item.addr"
                    placement="left"
                    effect="light"
                  >
                    <span>{{
                      item.addr.length > 9
                        ? item.addr.substr(0, 7) + '...'
                        : item.addr
                    }}</span>
                  </el-tooltip>
                </div>
              </div>
            </el-scrollbar>
          </div>
        </div>
      </div>
    </div>

    <ChangeProgram ref="changeProgramRef" @refresh="refresh" />

    <Alert ref="alertRef" @confirm="alertConfirm" />

    <!-- 添加设备弹窗 -->
    <AddDevice ref="addDeviceRef" @refresh="refresh" />
  </div>
</template>

<script>
import {
  GetProgramGroupApi,
  GetRuleCtrlDeviceApi,
  DelProgramApi,
  DelDeviceRuleApi
} from '@/api'
import ChangeProgram from './page2_cpts/change_program.vue'
import { deviceModeFmt } from '@/utils/filter.js'
import Alert from '@/components/alert/alert.vue'
import AddDevice from './page2_cpts/add_device.vue'

export default {
  components: { ChangeProgram, Alert, AddDevice },
  data() {
    return {
      id_task: '',
      // 顶栏三个禁用输入框内容
      ruleInfo: '',
      // 程序列表
      program: [],
      // 展开的程序
      expandId: '',
      // 当前规则下的设备列表
      deviceList: []
    }
  },
  methods: {
    // 点击返回
    goBack() {
      this.$parent.changePage(1)
    },

    // 父组件触发
    getData(row) {
      this.id_task = row.id
      this.getRuleDetail()
      this.getDeviceList()
    },

    // 获取规则的详情
    async getRuleDetail() {
      const params = {
        id_task: this.id_task
      }
      const { ret, data, msg } = await GetProgramGroupApi(params)
      if (ret !== 0) {
        return this.$message.error(msg)
      }
      this.ruleInfo = {
        name_operator: data.name_operator,
        name_project: data.name_project,
        name: data.name,
        id_project: data.id_project
      }
      this.program = data.program
      if (data.program[0]) {
        this.expandId = data.program[0].id
      }
    },

    // 获取规则下面的设备
    async getDeviceList() {
      const params = {
        id_task: this.id_task
      }
      const { ret, data, msg } = await GetRuleCtrlDeviceApi(params)
      if (ret !== 0) {
        return this.$message.error(msg)
      }
      this.deviceList = data
    },

    // 显示添加程序的弹窗
    showAddProgram() {
      this.$refs.changeProgramRef.add(this.id_task)
    },

    // 刷新
    refresh(type) {
      if (type === 1) {
        this.getRuleDetail()
      } else if (type === 2) {
        this.getDeviceList()
      }
    },

    // 动态计算时间
    getDateStr(row) {
      const startStr = (row.start_date + '').padStart(4, '0')
      const endStr = (row.end_date + '').padStart(4, '0')
      return `${startStr.substr(0, 2)}-${startStr.substr(2, 2)}—${endStr.substr(
        0,
        2
      )}-${endStr.substr(2, 2)}`
    },

    // 计算扫风模式
    getWindMode(row) {
      const result = []
      if (row.air_wind_left_right) {
        result.push('左右扫风')
      }
      if (row.air_wind_up_down) {
        result.push('上下扫风')
      }
      if (result.length) {
        return result.join('   ')
      } else {
        return '--'
      }
    },

    // 变更展开项
    expandChange(id) {
      if (this.expandId === id) {
        this.expandId = ''
      } else {
        this.expandId = id
      }
    },

    // 显示删除的弹窗
    showDelAlert(row) {
      this.$refs.alertRef.alert(
        {
          title: '删除提醒',
          msg: '您确定要删除该程序吗? 此操作不可恢复'
        },
        row,
        1
      )
    },

    // alert 事件发布
    alertConfirm({ info, type }) {
      if (type === 1) {
        this.delProgram(info)
      } else if (type === 2) {
        this.removeDevice(info)
      }
    },

    // 删除程序
    async delProgram(info) {
      const params = {
        id_program: info.id
      }
      const { ret, data, msg } = await DelProgramApi(params)
      if (ret !== 0) {
        return this.$message.error(msg)
      }
      this.$message.success('删除成功')
      this.getRuleDetail()
    },

    // 点击编辑程序
    editProgram(row) {
      this.$refs.changeProgramRef.edit(row)
    },

    // 显示删除设备弹窗
    showDelDeviceAlert(info) {
      this.$refs.alertRef.alert(
        {
          title: '删除提醒',
          msg: '你确定要把当前设备从该规则中移除吗?'
        },
        info,
        2
      )
    },

    // 移除设备
    async removeDevice(info) {
      const params = {
        id_device: info.id_device
      }
      const { ret, data, msg } = await DelDeviceRuleApi(params)
      if (ret !== 0) {
        return this.$message.error(msg)
      }
      this.$message.success('移除成功')
      this.getDeviceList()
    },

    // 显示添加设备的弹窗
    showAddDevice() {
      this.$refs.addDeviceRef.add(this.ruleInfo.id_project, this.id_task)
    }
  },
  filters: {
    deviceModeFmt
  },
  computed: {
    getLevel() {
      return this.$store.getters.level
    }
  }
}
</script>

<style lang="scss" scoped>
.intelligent_control {
  padding: 0 22px;
}

.card {
  margin-top: 24px;
  min-height: calc(100vh - 160px);
}

.title_box {
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #ebedf2;
  padding: 0 34px 0 26px;

  .left {
    display: flex;
    align-items: center;

    .color_block {
      width: 4px;
      height: 18px;
      background: linear-gradient(-75deg, #50e379, #50e398);
      box-shadow: 0px 3px 4px 0px rgba(80, 227, 146, 0.35);
      margin-right: 11px;
    }

    .title1 {
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #ccc;
    }

    .split {
      color: #12203e;
      font-weight: 600;
      margin: 0 8px;
    }

    .title2 {
      @extend .title1;
      color: #12203e;
      font-weight: 600;
    }
  }
}

.container_box {
  display: flex;
  padding: 20px;
  min-height: calc(100vh - 200px);

  .rule_box {
    flex: 1;
    min-height: calc(100vh - 240px);
    padding-right: 20px;

    .info_box {
      height: 56px;
    }

    .program_box {
      height: calc(100vh - 296px);
      background-color: #f8fafb;

      .title {
        height: 48px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 32px 0 30px;
        border-bottom: 1px solid #e8ecef;
      }

      .rule_item {
        height: calc(100vh - 344px);
        padding: 13px;

        .item {
          height: 348px;
          width: 100%;
          background: #ffffff;
          border: 1px solid #f1f1f5;
          margin-bottom: 16px;
          transition: height 0.3s linear;
          overflow: hidden;

          &.closed {
            height: 48px;
          }

          .head {
            height: 48px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0 21px 0 25px;
            border-bottom: 1px solid #e8ecef;

            .name {
              font-size: 14px;
              font-family: Adobe Heiti Std;
              font-weight: normal;
              color: #252631;
            }
          }

          .rule_container {
            height: 300px;
            padding: 24px;

            .table_box {
              width: 100%;
              height: 100%;
              display: flex;
              flex-direction: column;
              border: 1px solid #f1f1f5;

              .tr {
                flex: 1;
                display: flex;

                &:nth-child(2n + 1) {
                  background-color: #f8fafb;
                }

                .td {
                  flex: 1;
                  border-right: 1px solid #f1f1f5;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  font-size: 14px;
                  font-family: Adobe Heiti Std;
                  font-weight: normal;
                  color: #12203e;
                }

                .td2 {
                  flex: 2;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  font-size: 14px;
                  font-family: AlibabaPuHuiTi;
                  font-weight: 400;
                  color: #12203e;

                  span {
                    margin: 0 10px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .device_box {
    flex: 0 0 400px;
    min-height: calc(100vh - 240px);
    background: #f8fafb;

    .title_box {
      height: 48px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 33px 0 30px;

      .title {
        font-size: 14px;
        font-family: Adobe Heiti Std;
        font-weight: normal;
        color: #252631;
      }
    }

    .device_wrapper {
      height: calc(100vh - 288px);

      .device_list {
        padding: 0 30px;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        padding-top: 17px;

        .device {
          position: relative;
          width: 153px;
          height: 40px;
          background: #ffffff;
          border: 1px solid #0062ff;
          border-radius: 6px;
          margin: 0 0 17px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #0062ff;

          .close_box {
            position: absolute;
            right: 0;
            top: 0;
            width: 15px;
            height: 15px;
            background-color: #fff;
            border-radius: 50%;
            border: 1px solid #0062ff;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            transform: translate(50%, -50%);

            &:hover {
              background-color: #0062ff;

              i {
                color: #fff;
              }
            }
          }
        }
      }
    }
  }
}
</style>
