<template>
  <el-dialog :visible.sync="dialogVisible" title="图片预览" width="880px">
    <div class="img_box">
      <img :src="src" alt="" />
    </div>
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
      // 是否显示弹窗
      dialogVisible: false,
      // 图片路径
      src: ''
    }
  },
  methods: {
    // 添加账号
    open(src) {
      this.src = src
      this.dialogVisible = true
    }
  }
}
</script>

<style lang="scss" scoped>
.img_box {
  width: 100%;
  height: auto;

  img {
    width: 100%;
    height: auto;
  }
}
</style>
